import VerticalLine_VerticalDoubleLineComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/VerticalDoubleLine.skin';


const VerticalLine_VerticalDoubleLine = {
  component: VerticalLine_VerticalDoubleLineComponent
};


export const components = {
  ['VerticalLine_VerticalDoubleLine']: VerticalLine_VerticalDoubleLine
};

